import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import axios from 'axios';
import unterseite from '../images/unterseite.webp'
import search from '../images/search.svg'

function filterOutliers(someArray) {  

    // Copy the values, rather than operating on references to existing values
    var values = someArray.concat();

    // Then sort
    values.sort( function(a, b) {
            return parseInt(a.price) - parseInt(b.price);
         });

    /* Then find a generous IQR. This is generous because if (values.length / 4) 
     * is not an int, then really you should average the two elements on either 
     * side to find q1.
     */     
    var q1 = parseInt(values[Math.floor((values.length / 4))].price);
    // Likewise for q3. 
    var q3 = parseInt(values[Math.ceil((values.length * (3 / 4)))].price);
    var iqr = q3 - q1;

    // Then find min and max values
    var maxValue = q3 + iqr*1.5;
    var minValue = q1 - iqr*1.5;

    // Then filter anything beyond or beneath these values.
    var filteredValues = values.filter(function(x) {
        return (parseInt(x.price) <= maxValue) && (parseInt(x.price) >= minValue);
    });

    // Then return
    return filteredValues;
}

class PreisRechner extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], search: "", fetching: false, focus: false }
    }
    
    render() {
        let items = this.state.data
        let brandNew = []
        let used = []
        for (let i in items) {
            if (items[i].condition.toLowerCase().includes("neu")) {
                brandNew.push(items[i])
            } else {
                used.push(items[i])
            }
        }

        if (brandNew.length > 4) 
            brandNew = filterOutliers(brandNew)
        if (used.length > 4)
            used = filterOutliers(used)
        let showResult = items.length > 0 && !this.state.fetching
        
        return (
            <Layout>
                <Seo title="Preis Rechner"/> 
               <div className='content price-checker'>
                    
                    <div className='header' style={{textAlign: "center"}}>
                        <h1>Hot Wheels Preis Checker</h1> 
                        <h2>Finde schnell und einfach heraus wieviel dein Hot Wheels Auto ungefähr wert ist!</h2>
                        <div className={this.state.focus ? 'search focus' : 'search'} style={{border: "1px solid #000", margin: "50px 0px", display: "flex"}}>
                        <div style={{padding: 9, marginTop: "-1px", borderRight: "1px solid #000", width: 40}}>
                            <img style={{display: "flex", margin: "auto"}} alt="suche" src={search} />
                        </div>
                        <form style={{width: "100%", display: "flex"}} onSubmit={(e) => {
                                e.preventDefault()
                                this.setState({fetching: true})
                                axios.get("/.netlify/functions/ebay?search=" + this.state.search).then((res) => {
                                    this.setState({data: res.data.results, fetching: false})
                                })
                            }}>
                        <input placeholder="z.B 2009 HW Racing Corvette" onBlur={() => this.setState({focus: false})} onFocus={() => this.setState({focus: true})} style={{minWidth: "90%", border: "none", outline: "none", marginLeft: 5, background: "none", borderRadius: 5, fontSize: 22, padding: "7px 0px"}} 
                            value={this.state.search} onChange={(e) => {
                                this.setState({search: e.target.value})
                            }}/> 
                        </form>
                        </div>
                    </div>
                    {showResult &&
                        <div className='row' style={{color: 'var(--color-primary)', justifyContent: "space-around", margin: "75px auto"}}>
                            <div className='new'>
                                <h2 style={{margin: "0px"}}>Neuwert: ~{(brandNew.reduce((a, b) => a + parseFloat(b.price), 0) / brandNew.length).toFixed(2) + "€"}</h2>
                            </div>
                            <div className='used' >
                                <h2 style={{margin: "0px"}}>Gebrauchtwert: ~{((used.reduce((a, b) => a + parseFloat(b.price), 0)) / used.length ).toFixed(2) + "€"}</h2>
                            </div>
                        </div>
                    }
                    {this.state.fetching &&
                        <div className='row' style={{color: 'var(--color-primary)', justifyContent: "space-around", margin: "75px auto"}}>
                            <h2>Die Suche kann ein paar Sekunden dauern.</h2>
                        </div>
                    }
                    
                    <h4>Wie finde ich das Herstellungsjahr für mein Hot Wheels Auto?</h4>
                    <p>Wenn eine Jahreszahl zusehen ist, dann ist dass meistens das Copyright Datum und nicht das Jahr in dem das Auto hergestellt wurde. Um das Herstellungsjahr heraus zubekommen müssen sie den Code auf der Unterseite des Autos finden. Allerdings ist der Code nur auf den Autos wenn es nach 2008 hergestellt wurde.</p>
                    <br /> <br />
                    <div className='row' style={{justifyContent: "space-around"}}>
                    <table style={{textAlign: "center", marginTop: 15}}>
                        <thead>
                            <tr>
                                <th style={{fontWeight: "bold", paddingRight: "5px"}}>Code Buchstabe</th>
                                <th style={{fontWeight: "bold", padding: "0px 5px"}}>Herstellungsjahr</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>A</td>
                                <td>2008</td>
                            </tr>
                            <tr>
                                <td>B</td>
                                <td>2009</td>
                            </tr>
                            <tr>    
                                <td>C</td>
                                <td>2010</td>
                            </tr>
                            <tr>
                                <td>D</td>
                                <td>2011</td>
                            </tr>
                            <tr>
                                <td>E</td>
                                <td>2012</td>
                            </tr>
                            <tr>
                                <td>F</td>
                                <td>2013</td>
                            </tr>
                            <tr>
                                <td>G</td>
                                <td>2014</td>
                            </tr>
                            <tr>
                                <td>H</td>
                                <td>2015</td>
                            </tr>
                            <tr>
                                <td>J</td>
                                <td>2016</td>
                            </tr>
                            <tr>
                                <td>K</td>
                                <td>2017</td>
                            </tr>
                            <tr>
                                <td>L</td>
                                <td>2018</td>
                            </tr>
                            <tr>
                                <td>M</td>
                                <td>2019</td>
                            </tr>
                            <tr>
                                <td>N</td>
                                <td>2020</td>
                            </tr>
                            <tr>
                                <td>P</td>
                                <td>2021</td>
                            </tr>
                            <tr>
                                <td>Q</td>
                                <td>2022</td>
                            </tr>
                        </tbody>
                        
                    </table>
                    <img src={unterseite} alt="unterseite auto modellnummer" style={{width: "60%"}}/>
                    
                    </div>
                    

               </div>
               
            </Layout>
        )
    }
    
}

// <a className='amazon-button' href={item.url + "&tag=niklasoelze-21"} rel="nofollow" target={"_blank"} style={{padding: 5, display: "block"}}>Weiter zu Amazon</a>

 export default PreisRechner